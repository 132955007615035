import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../components/organisms/styles/MainContent.css';
import '../components/organisms/styles/Resume.css';
import { getDataFromContentful } from '../utils/getDataFromContentful';
import { queryResume } from '../querys/queryResume';
import { generalStructure } from '../utils/generalStructure';
import { faArrowUpRightFromSquare, faCheck } from '@fortawesome/free-solid-svg-icons';
import ShowCredentialButton from '../components/molecules/ShowCredentialButton';
import Skills from '../components/molecules/Skills';
import { skillsGlovesStructure } from '../utils/skillsGlovesStructure';
import ItemList from '../components/atoms/ItemList';

interface SkillItem {
    name: string;
    percentage: number;
}

interface TransformedSkill {
    skill: string;
    data: [number, number];
    backgroundColor: [string, string];
}

function transformSkillsArray(skillsArray: SkillItem[]): TransformedSkill[] {
    const transformedArray: TransformedSkill[] = skillsArray.map((item) => ({
        skill: item.name,
        data: [item.percentage, 100 - item.percentage],
        backgroundColor: ['#6daaf5', '#292929']
    }));

    return transformedArray;
}

export default function Resume() {

    const [experiences, setExperiences] = useState(generalStructure);
    const [studies, setStudies] = useState(generalStructure);
    const [certifications, setCertifications] = useState(generalStructure);
    const [vtexTraining, setVtexTraining] = useState(generalStructure);
    const [skillsGlobesData, setSkillsGlobeData] = useState(transformSkillsArray(skillsGlovesStructure));
    const [knowledge, setKnowledge] = useState(generalStructure);

    useEffect(() => {
        getDataFromContentful({
            query: queryResume
        }).then((response) => {
            if (response.data.generalInformationInTheResumeCollection.items) {
                setExperiences(response.data.generalInformationInTheResumeCollection.items)
            }
            if (response.data.informationAboutStudiesInTheCurriculumCollection.items) {
                setStudies(response.data.informationAboutStudiesInTheCurriculumCollection.items)
            }
            if (response.data.informationAboutLicensesAndCertificationsCollection.items) {
                setCertifications(response.data.informationAboutLicensesAndCertificationsCollection.items)
            }
            if (response.data.vtexTrainingCollection.items) {
                setVtexTraining(response.data.vtexTrainingCollection.items)
            }
            if (response.data.skillsGlobesCollection.items) {
                setSkillsGlobeData(transformSkillsArray(response.data.skillsGlobesCollection.items))
            }
            if (response.data.knowledgeCollection.items) {
                setKnowledge(response.data.knowledgeCollection.items)
            }
        })
    }, [])


    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://www.serbeld.space/blog" />

                <title>Resume | Sergio Beleño - Tech Leader & Full Stack Developer</title>
                <meta name="description" content="Discover the expertise of Sergio Beleño, a seasoned Technical Leader and Full Stack Web Developer. Explore his skills, professional experience, and achievements in the tech industry. Connect with Sergio for leadership in innovative web development projects." />

                <meta property="og:locale" content="en_CO" />
                <meta property="og:title" content="Resume | Sergio Beleño - Tech Leader & Full Stack Developer" />
                <meta property="og:description" content="Discover the expertise of Sergio Beleño, a seasoned Technical Leader and Full Stack Web Developer. Explore his skills, professional experience, and achievements in the tech industry. Connect with Sergio for leadership in innovative web development projects." />
                <meta property="og:url" content="https://www.serbeld.space/blog" />
                <meta property="og:site_name" content="Sergio Beleño's Blog" />
                <meta property="og:type" content="website" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@serbeld" />
                <meta name="twitter:title" content="Resume | Sergio Beleño - Tech Leader & Full Stack Developer" />
                <meta name="twitter:description" content="Discover the expertise of Sergio Beleño, a seasoned Technical Leader and Full Stack Web Developer. Explore his skills, professional experience, and achievements in the tech industry. Connect with Sergio for leadership in innovative web development projects." />
                <meta name="twitter:url" content="https://www.serbeld.space/blog" />
            </Helmet>

            <div className="card-wrap">
                <div className="content resume">
                    <h1>RESUME</h1>
                    <div className='two-columns'>
                        <ul>
                            <li className='title-h2'>EXPERIENCE</li>
                            {
                                experiences.map((experience, index) =>
                                    <li key={`experience-${index}`}>
                                        <div className='left'>
                                            {
                                                experience.date ?
                                                    <span className={`date${(Number(index) === 0) ? ' highlighted' : ''}`}>{experience.date}</span>
                                                    :
                                                    null
                                            }
                                            {
                                                experience.name ?
                                                    <h2 className='name' title={experience.name}>{experience.name}</h2>
                                                    :
                                                    null
                                            }
                                            {
                                                experience.location ?
                                                    <h6 className='location'>{experience.location}</h6>
                                                    :
                                                    null
                                            }
                                            {
                                                experience.company ?
                                                    <h6 className='company'>{experience.company}</h6>
                                                    :
                                                    null
                                            }
                                            {
                                                experience.singlePostText ?
                                                    <h6 className='singlePostText'>{experience.singlePostText}</h6>
                                                    :
                                                    null
                                            }
                                        </div>
                                        {
                                            experience.image && experience.image.url ?
                                                <div className='right'>

                                                    <img src={experience.image.url}
                                                        alt={experience.image.description}
                                                        title={experience.image.title} />
                                                </div>
                                                :
                                                null
                                        }
                                    </li>
                                )
                            }
                            <li className='title-h2'>VTEX TRAINING</li>
                            {
                                vtexTraining.map((study, index) =>
                                    <li key={`vtex-training-${index}`}>
                                        <div className='left'>
                                            {
                                                study.date ?
                                                    <span className={`date${(Number(index) === 0) ? ' highlighted' : ''}`}>{study.date}</span>
                                                    :
                                                    null
                                            }
                                            {
                                                study.name ?
                                                    <h2 className='name' title={study.name}>{study.name}</h2>
                                                    :
                                                    null
                                            }
                                            {
                                                study.location ?
                                                    <h6 className='location'>{study.location}</h6>
                                                    :
                                                    null
                                            }
                                            {
                                                study.institute ?
                                                    <h6 className='institute'>{study.institute}</h6>
                                                    :
                                                    null
                                            }
                                            {
                                                study.singlePostText ?
                                                    <h6 className='singlePostText'>{study.singlePostText}</h6>
                                                    :
                                                    null
                                            }
                                            {
                                                study.url ?
                                                    <ShowCredentialButton
                                                        icon={faArrowUpRightFromSquare}
                                                        href={study.url}
                                                        blank={true}
                                                    />
                                                    :
                                                    null
                                            }
                                        </div>
                                        {
                                            study.image && study.image.url ?
                                                <div className='right'>

                                                    <img src={study.image.url}
                                                        alt={study.image.description}
                                                        title={study.image.title} />
                                                </div>
                                                :
                                                null
                                        }
                                    </li>
                                )
                            }
                        </ul>
                        <ul>
                            <li className='title-h2'>EDUCATION</li>
                            {
                                studies.map((study, index) =>
                                    <li key={`education-${index}`}>
                                        <div className='left'>
                                            {
                                                study.date ?
                                                    <span className={`date${(Number(index) === 0) ? ' highlighted' : ''}`}>{study.date}</span>
                                                    :
                                                    null
                                            }
                                            {
                                                study.name ?
                                                    <h2 className='name' title={study.name}>{study.name}</h2>
                                                    :
                                                    null
                                            }
                                            {
                                                study.location ?
                                                    <h6 className='location'>{study.location}</h6>
                                                    :
                                                    null
                                            }
                                            {
                                                study.institute ?
                                                    <h6 className='institute'>{study.institute}</h6>
                                                    :
                                                    null
                                            }
                                            {
                                                study.singlePostText ?
                                                    <h6 className='singlePostText'>{study.singlePostText}</h6>
                                                    :
                                                    null
                                            }
                                        </div>
                                        {
                                            study.image && study.image.url ?
                                                <div className='right'>

                                                    <img src={study.image.url}
                                                        alt={study.image.description}
                                                        title={study.image.title} />
                                                </div>
                                                :
                                                null
                                        }
                                    </li>
                                )
                            }
                            <li className='title-h2'>LICENSES AND CERTIFICATIONS</li>
                            {
                                certifications.map((certification, index) =>
                                    <li key={`certification-${index}`}>
                                        <div className='left'>
                                            {
                                                certification.date ?
                                                    <span className={`date${(Number(index) === 0) ? ' highlighted' : ''}`}>{certification.date}</span>
                                                    :
                                                    null
                                            }
                                            {
                                                certification.name ?
                                                    <h2 className='name' title={certification.name}>{certification.name}</h2>
                                                    :
                                                    null
                                            }
                                            {
                                                certification.location ?
                                                    <h6 className='location'>{certification.location}</h6>
                                                    :
                                                    null
                                            }
                                            {
                                                certification.institute ?
                                                    <h6 className='institute'>{certification.institute}</h6>
                                                    :
                                                    null
                                            }
                                            {
                                                certification.singlePostText ?
                                                    <h6 className='singlePostText'>{certification.singlePostText}</h6>
                                                    :
                                                    null
                                            }
                                            {
                                                certification.url ?
                                                    <ShowCredentialButton
                                                        icon={faArrowUpRightFromSquare}
                                                        href={certification.url}
                                                        blank={true}
                                                    />
                                                    :
                                                    null
                                            }
                                        </div>
                                        {
                                            certification.image && certification.image.url ?
                                                <div className='right'>

                                                    <img src={certification.image.url}
                                                        alt={certification.image.description}
                                                        title={certification.image.title} />
                                                </div>
                                                :
                                                null
                                        }
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                    <div className='flex'>
                        <div className='item-flex'>
                            <h2 className='h1-title'>SKILLS</h2>
                            <div className='skills-container'>
                                <Skills skillsData={skillsGlobesData} />
                            </div>
                        </div>
                        <div className='item-flex'>
                            <h2 className='h1-title'>KNOWLEDGE</h2>
                            <div className='skills-container'>
                                {
                                    knowledge.map((knowledgeItem, index) =>
                                        knowledgeItem ? (
                                            <ItemList 
                                                key={`knowledge-${index}`}  // Added key prop
                                                text={knowledgeItem.item}
                                                icon={faCheck} 
                                            />
                                        ) : null
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
