import { HashRouter, Routes, Route } from "react-router-dom";

// pages
import HomePage from "./pages/HomePage";
import NotFoundPage from "./pages/NotFoundPage";
import BlogPage from "./pages/BlogPage";
import CalendlyWidget from "./pages/CalendlyWidget";
import Resume from "./pages/Resume";

import Header from "./components/organisms/Header";
import GeneralInfoCardProfile from "./components/organisms/GeneralInfoCardProfile";

import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
// import { loadAll } from "@tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.

function App() {
  const [init, setInit] = useState(false);

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const options = useMemo(
    () => ({
      background: {
        color: {
          value: "#0f0f0f",
        },
      },
      fpsLimit: 120,
      interactivity: {
        modes:{
          grab: {
            distance: 250,
            line_linked: {
              "opacity": 1
            }
          }
        },
        events: {
          onHover: {
            enable: true,
            mode: "grab",
          },
        },
      },
      particles: {
        color: {
          value: "#ffffff",
        },
        links: {
          color: "#ffffff",
          distance: 200,
          enable: true,
          opacity: 0.5,
          width: 1,
        },
        move: {
          direction: "none",
          enable: true,
          outModes: {
            default: "bounce",
          },
          random: false,
          speed: 6,
          straight: false,
        },
        number: {
          density: {
            enable: true,
            value_area: 800,
          },
          value: 150,
        },
        opacity: {
          value: 0.5,
          random: true,
          anim: {
            enable: true,
            speed: 0.1,
            opacity_min: 0.1,
            sync: false
          }
        },
        shape: {
          type: "circle",
        },
        size: {
          value: { min: 1, max: 5 },
        },
      },
      detectRetina: true,
    }),
    []
  );

  if (init) {
    return (
      <HashRouter>
        <main>
          <Particles
            id="tsparticles"
            particlesLoaded={particlesLoaded}
            options={options}
          />

          <div className="App" id="website">
          
            
        <div className='flex' id='person'>
            <Header />
            <GeneralInfoCardProfile />
            <Routes>
              <Route index element={<HomePage />} />
              <Route path="home" element={<HomePage />} />
              <Route path="blog" element={<BlogPage />} />
              <Route path="contact" element={<CalendlyWidget />} />
              <Route path="resume" element={<Resume />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
            </div>
          </div>
        </main>
      </HashRouter>
    );
  }

  return (
    <>
    </>
  );
}

export default App;
