import { useState } from 'react';
import './styles/infoCardProfile.css';
import './styles/MainContent.css';

const InfoCardProfile = () => {

    let day = new Date();
    const[yearsOld] = useState((day.getUTCFullYear() - 1997));

    return (
        <div className="card-wrap">
            <div className="content">
                <h1 className="title bold">
                    <span>About Me</span>
                </h1>
                <div className='description'>
                    <h2 className='bold'>
                        Hello! I'm Sergio Beleño.
                    </h2>
                    <h3 className='light no-h'>
                        Technical leader and Full Stack web developer, specialized in VTEX, SEO and creating impactful e-commerce experiences. An experienced professional with proficiency in HTML, CSS, JavaScript, React JS, Node JS, and more. I guarantee cutting-edge solutions for transformative online experiences.
                    </h3>
                    <div className='pt1'>
                        <div className="info-list">
                            <ul>
                                <li>
                                    <strong>
                                        <span> Age </span>
                                    </strong>
                                    <span> {yearsOld} </span>
                                </li>
                                <li>
                                    <strong>
                                        <span> Residence </span>
                                    </strong>
                                    <span> CO </span>
                                </li>
                                <li>
                                    <strong>
                                        <span> Freelance </span>
                                    </strong>
                                    <span> Available </span>
                                </li>
                                <li>
                                    <strong>
                                        <span> Address </span>
                                    </strong>
                                    <span> Bogotá DC, CO </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <h3 className="title bold">
                    <span>My Services</span>
                </h3>

                <div className="flex row service-items border-line-v">
                    {" "}
                    {/* service item */}
                    <div className="col col-d-6 col-t-6 col-m-12 border-line-h">
                        <div className="service-item">
                            <div className="icon">
                                {" "}
                                <svg width="100%" height="100%" viewBox="-10.5 -9.45 21 18.9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="0" cy="0" r="2" fill="currentColor"></circle>
                                    <g stroke="currentColor" strokeWidth="1" fill="none">
                                        <ellipse rx="10" ry="4.5"></ellipse>
                                        <ellipse rx="10" ry="4.5" transform="rotate(60)"></ellipse>
                                        <ellipse rx="10" ry="4.5" transform="rotate(120)"></ellipse>
                                    </g>
                                </svg>
                            </div>
                            <div className="name">
                                {" "}
                                <h4 className='bold'> Front-end using HTML, CSS, Javascript and React JS </h4>
                            </div>
                            <div className="desc">
                                <div>
                                    <p>
                                        Elevate your brand with a modern and mobile-responsive website designed to amplify your marketing efforts and ensure maximum reach.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                    {/* service item */}
                    <div className="col col-d-6 col-t-6 col-m-12 border-line-h">
                        <div className="service-item">
                            <div className="icon">
                                {" "}
                                <svg xmlns="http://www.w3.org/2000/svg" width="589.827" height="361.238" version="1.2" viewBox="0 0 442.37 270.929">
                                    <defs>
                                        <clipPath id="a">
                                            <path d="M239.03 226.605l-42.13 24.317c-1.578.91-2.546 2.59-2.546 4.406v48.668c0 1.817.968 3.496 2.546 4.406l42.133 24.336c1.575.907 3.517.907 5.09 0l42.126-24.336c1.57-.91 2.54-2.59 2.54-4.406v-48.668c0-1.816-.97-3.496-2.55-4.406l-42.12-24.317c-.79-.453-1.67-.68-2.55-.68-.88 0-1.76.227-2.55.68" />
                                        </clipPath>
                                        <linearGradient id="b" x1="-.348" x2="1.251" gradientTransform="rotate(116.114 53.1 202.97) scale(86.48)" gradientUnits="userSpaceOnUse">
                                            <stop offset=".3" stopColor="#3E863D" />
                                            <stop offset=".5" stopColor="#55934F" />
                                            <stop offset=".8" stopColor="#5AAD45" />
                                        </linearGradient>
                                        <clipPath id="c">
                                            <path d="M195.398 307.086c.403.523.907.976 1.5 1.316l36.14 20.875 6.02 3.46c.9.52 1.926.74 2.934.665.336-.027.672-.09 1-.183l44.434-81.36c-.34-.37-.738-.68-1.184-.94l-27.586-15.93-14.582-8.39c-.414-.24-.863-.41-1.32-.53zm0 0" />
                                        </clipPath>
                                        <linearGradient id="d" x1="-.456" x2=".582" gradientTransform="rotate(-36.46 550.846 -214.337) scale(132.798)" gradientUnits="userSpaceOnUse">
                                            <stop offset=".57" stopColor="#3E863D" />
                                            <stop offset=".72" stopColor="#619857" />
                                            <stop offset="1" stopColor="#76AC64" />
                                        </linearGradient>
                                        <clipPath id="e">
                                            <path d="M241.066 225.953c-.707.07-1.398.29-2.035.652l-42.01 24.247 45.3 82.51c.63-.09 1.25-.3 1.81-.624l42.13-24.336c1.3-.754 2.19-2.03 2.46-3.476l-46.18-78.89c-.34-.067-.68-.102-1.03-.102-.14 0-.28.007-.42.02" />
                                        </clipPath>
                                        <linearGradient id="f" x1=".043" x2=".984" gradientTransform="translate(192.862 279.652) scale(97.417)" gradientUnits="userSpaceOnUse">
                                            <stop offset=".16" stopColor="#6BBF47" />
                                            <stop offset=".38" stopColor="#79B461" />
                                            <stop offset=".47" stopColor="#75AC64" />
                                            <stop offset=".7" stopColor="#659E5A" />
                                            <stop offset=".9" stopColor="#3E863D" />
                                        </linearGradient>
                                    </defs>
                                    <path fill="var(--selected-item-color)" d="M218.647 270.93c-1.46 0-2.91-.383-4.19-1.12l-13.337-7.896c-1.992-1.114-1.02-1.508-.363-1.735 2.656-.93 3.195-1.14 6.03-2.75.298-.17.688-.11.993.07l10.246 6.08c.37.2.895.2 1.238 0l39.95-23.06c.37-.21.61-.64.61-1.08v-46.1c0-.46-.24-.87-.618-1.1l-39.934-23.04c-.37-.22-.86-.22-1.23 0l-39.926 23.04c-.387.22-.633.65-.633 1.09v46.1c0 .44.24.86.62 1.07l10.94 6.32c5.94 2.97 9.57-.53 9.57-4.05v-45.5c0-.65.51-1.15 1.16-1.15h5.06c.63 0 1.15.5 1.15 1.15v45.52c0 7.92-4.32 12.47-11.83 12.47-2.31 0-4.13 0-9.21-2.5l-10.48-6.04c-2.59-1.5-4.19-4.3-4.19-7.29v-46.1c0-3 1.6-5.8 4.19-7.28l39.99-23.07c2.53-1.43 5.89-1.43 8.4 0l39.94 23.08c2.58 1.49 4.19 4.28 4.19 7.28v46.1c0 2.99-1.61 5.78-4.19 7.28l-39.94 23.07c-1.28.74-2.73 1.12-4.21 1.12" />
                                    <path fill="var(--selected-item-color)" d="M230.987 239.164c-17.48 0-21.145-8.024-21.145-14.754 0-.64.516-1.15 1.157-1.15h5.16c.57 0 1.05.415 1.14.978.78 5.258 3.1 7.91 13.67 7.91 8.42 0 12-1.902 12-6.367 0-2.57-1.02-4.48-14.1-5.76-10.94-1.08-17.7-3.49-17.7-12.24 0-8.06 6.8-12.86 18.19-12.86 12.79 0 19.13 4.44 19.93 13.98.03.33-.09.65-.31.89-.22.23-.53.37-.85.37h-5.19c-.54 0-1.01-.38-1.12-.9-1.25-5.53-4.27-7.3-12.48-7.3-9.19 0-10.26 3.2-10.26 5.6 0 2.91 1.26 3.76 13.66 5.4 12.28 1.63 18.11 3.93 18.11 12.56 0 8.7-7.26 13.69-19.92 13.69m48.66-48.89h1.34c1.1 0 1.31-.77 1.31-1.22 0-1.18-.81-1.18-1.26-1.18h-1.38zm-1.63-3.78h2.97c1.02 0 3.02 0 3.02 2.28 0 1.59-1.02 1.92-1.63 2.12 1.19.08 1.27.86 1.43 1.96.08.69.21 1.88.45 2.28h-1.83c-.05-.4-.33-2.6-.33-2.72-.12-.49-.29-.73-.9-.73h-1.51v3.46h-1.67zm-3.57 4.3c0 3.58 2.89 6.48 6.44 6.48 3.58 0 6.47-2.96 6.47-6.48 0-3.59-2.93-6.44-6.48-6.44-3.5 0-6.44 2.81-6.44 6.43m14.16.03c0 4.24-3.47 7.7-7.7 7.7-4.2 0-7.7-3.42-7.7-7.7 0-4.36 3.58-7.7 7.7-7.7 4.15 0 7.69 3.35 7.69 7.7" />
                                    <path fill="var(--selected-item-color)" fillRule="evenodd" d="M94.936 90.55c0-1.84-.97-3.53-2.558-4.445l-42.356-24.37c-.715-.42-1.516-.64-2.328-.67h-.438c-.812.03-1.613.25-2.34.67L2.562 86.105C.984 87.025 0 88.715 0 90.555l.093 65.64c0 .91.47 1.76 1.27 2.21.78.48 1.76.48 2.54 0l25.18-14.42c1.59-.946 2.56-2.618 2.56-4.44V108.88c0-1.83.97-3.52 2.555-4.43l10.72-6.174c.796-.46 1.67-.688 2.56-.688.876 0 1.77.226 2.544.687l10.715 6.172c1.586.91 2.56 2.6 2.56 4.43v30.663c0 1.82.983 3.5 2.565 4.44l25.164 14.41c.79.47 1.773.47 2.56 0 .776-.45 1.268-1.3 1.268-2.21zm199.868 34.176c0 .457-.243.88-.64 1.106l-14.548 8.386c-.395.227-.883.227-1.277 0l-14.55-8.386c-.4-.227-.64-.65-.64-1.106V107.93c0-.458.24-.88.63-1.11l14.54-8.4c.4-.23.89-.23 1.29 0l14.55 8.4c.4.23.64.652.64 1.11zM298.734.324c-.794-.442-1.76-.43-2.544.027-.78.46-1.262 1.3-1.262 2.21v65c0 .64-.34 1.23-.894 1.55-.55.32-1.235.32-1.79 0L281.634 63c-1.58-.914-3.526-.914-5.112 0l-42.37 24.453c-1.583.91-2.56 2.6-2.56 4.42v48.92c0 1.83.977 3.51 2.56 4.43l42.37 24.47c1.582.91 3.53.91 5.117 0l42.37-24.48c1.58-.92 2.56-2.6 2.56-4.43V18.863c0-1.856-1.01-3.563-2.63-4.47zm141.093 107.164c1.574-.914 2.543-2.602 2.543-4.422V91.21c0-1.824-.97-3.507-2.547-4.425l-42.1-24.44c-1.59-.92-3.54-.92-5.13 0l-42.36 24.45c-1.59.92-2.56 2.6-2.56 4.43v48.9c0 1.84.99 3.54 2.58 4.45l42.09 23.99c1.55.89 3.45.9 5.02.03l25.46-14.15c.8-.45 1.31-1.3 1.31-2.22 0-.92-.49-1.78-1.29-2.23l-42.62-24.46c-.8-.45-1.29-1.3-1.29-2.21v-15.34c0-.916.48-1.76 1.28-2.216l13.26-7.65c.79-.46 1.76-.46 2.55 0l13.27 7.65c.79.45 1.28 1.3 1.28 2.21v12.06c0 .91.49 1.76 1.28 2.22.79.45 1.77.45 2.56-.01zm0 0" />
                                    <path fill="var(--selected-item-color)" fillRule="evenodd" d="M394.538 105.2c.3-.177.676-.177.98 0l8.13 4.69c.304.176.49.5.49.85v9.39c0 .35-.186.674-.49.85l-8.13 4.69c-.304.177-.68.177-.98 0l-8.125-4.69c-.31-.176-.5-.5-.5-.85v-9.39c0-.35.18-.674.49-.85zm0 0" />
                                    <g clipPath="url(#a)" transform="translate(-78.306 -164.016)">
                                        <path fill="var(--selected-item-color)" d="M331.363 246.793l-118.715-58.19-60.87 124.174L270.49 370.97zm0 0" />
                                    </g>
                                    <g clipPath="url(#c)" transform="translate(-78.306 -164.016)">
                                        <path fill="var(--selected-item-color)" d="M144.07 264.004l83.825 113.453 110.86-81.906-83.83-113.45zm0 0" />
                                    </g>
                                    <g clipPath="url(#e)" transform="translate(-78.306 -164.016)">
                                        <path fill="var(--selected-item-color)" d="M197.02 225.934v107.43h91.683v-107.43zm0 0" />
                                    </g>
                                </svg>
                            </div>
                            <div className="name">
                                {" "}
                                <h4 className='bold'> Back-End using Node JS </h4>
                            </div>
                            <div className="desc">
                                <div>
                                    <p>
                                        With Node.js expertise, I craft powerful backends for efficient, reliable, and high-performance marketing support.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                    {/* service item */}
                    <div className="col col-d-6 col-t-6 col-m-12">
                        <div className="service-item">
                            <div className="icon">
                                {" "}
                                <svg width="304.60388" height="109.53113" viewBox="0 0 304.60388 109.53113" version="1.1" id="svg20">
                                    <g id="Page-1" stroke="none" transform="translate(-48.186108,-47.31959)">
                                        <g id="Artboard">
                                            <g id="vtex-logo" transform="translate(48,47)">
                                                <path d="m 220.35,41.34 h -10.92 v 37.38 c -0.005,0.704658 -0.57534,1.274558 -1.28,1.28 h -8.41 c -0.70466,-0.0054 -1.27456,-0.575342 -1.28,-1.28 V 41.34 h -11 c -0.33306,0.01356 -0.65736,-0.108641 -0.89866,-0.338623 C 186.32005,40.771395 186.18243,40.453328 186.18,40.12 V 33.5 c 0.002,-0.333328 0.14005,-0.651395 0.38134,-0.881377 0.2413,-0.229982 0.5656,-0.352182 0.89866,-0.338623 h 32.87 c 0.70901,-0.03402 1.3123,0.511172 1.35,1.22 v 6.62 c -0.0377,0.700724 -0.62863,1.242773 -1.33,1.22 z" id="Shape" />
                                                <path d="m 255.37,79.75 c -4.30509,0.615781 -8.65146,0.896624 -13,0.84 -8.29,0 -15.61,-2.12 -15.61,-13.81 V 45.45 c 0,-11.69 7.39,-13.74 15.67,-13.74 4.31504,-0.05911 8.62812,0.218397 12.9,0.83 0.9,0.13 1.28,0.45 1.28,1.28 v 6 c -0.005,0.704658 -0.57534,1.274558 -1.28,1.28 h -13.5 c -3,0 -4.11,1 -4.11,4.37 v 5.84 h 17.14 c 0.70466,0.0054 1.27456,0.575342 1.28,1.28 v 6.1 c -0.005,0.704658 -0.57534,1.274558 -1.28,1.28 h -17.14 v 6.81 c 0,3.34 1.09,4.37 4.11,4.37 h 13.54 c 0.70466,0.0054 1.27456,0.575342 1.28,1.28 v 6 c 0.01,0.8 -0.38,1.19 -1.28,1.32 z" id="path9" />
                                                <path d="m 303.83,80 h -10.21 c -0.71202,0.0529 -1.38046,-0.347368 -1.67,-1 l -8.86,-14 -8,13.74 c -0.45,0.77 -0.9,1.28 -1.61,1.28 H 264 c -0.24831,0.04452 -0.50356,-0.02356 -0.69672,-0.18581 C 263.11012,79.671935 262.99901,79.432264 263,79.18 c 0.0131,-0.157131 0.0573,-0.310094 0.13,-0.45 L 277.06,55.54 263,33.5 c -0.0725,-0.118382 -0.11698,-0.251786 -0.13,-0.39 0.0476,-0.506319 0.49306,-0.880511 1,-0.84 h 10.34 c 0.71,0 1.22,0.64 1.61,1.22 l 8.22,13 8,-13 c 0.28992,-0.657457 0.89863,-1.118721 1.61,-1.22 h 9.51 c 0.50694,-0.04051 0.9524,0.333681 1,0.84 -0.013,0.138214 -0.0575,0.271618 -0.13,0.39 l -14,22.17 14.57,23 c 0.11259,0.195504 0.17768,0.414728 0.19,0.64 -0.12,0.44 -0.45,0.69 -0.96,0.69 z" id="path11" />
                                                <path d="m 170.8,32.41 c -0.47969,-0.01067 -0.89936,0.320862 -1,0.79 l -9.33,34.52 c -0.13,0.71 -0.32,1 -0.9,1 -0.58,0 -0.77,-0.26 -0.9,-1 l -9.3,-34.52 c -0.10064,-0.469138 -0.52031,-0.800674 -1,-0.79 h -9.18 c -0.30814,-0.0076 -0.60256,0.127382 -0.79796,0.36577 -0.1954,0.238388 -0.26995,0.553566 -0.20204,0.85423 0,0 11.39,39.57 11.51,40 1.52,4.72 5.21,7 9.9,7 4.49956,0.161453 8.55216,-2.704023 9.9,-7 0.18,-0.54 11.32,-40 11.32,-40 0.0643,-0.2992 -0.0119,-0.611392 -0.20682,-0.847296 C 180.41822,32.5468 180.12596,32.413159 179.82,32.42 Z" id="path13" />
                                                <path d="M 118.77,0.32 H 23.05 C 19.586315,0.35166678 16.388901,2.1841531 14.61068,5.1567016 12.832459,8.12925 12.729906,11.813127 14.34,14.88 l 9.58,18.24 H 6.56 C 4.3421294,33.079643 2.2685207,34.216027 1.109161,36.107181 -0.05019865,37.998335 -0.12215931,40.361817 0.92,42.32 l 30.8,58.2 c 1.10523,2.09008 3.275685,3.39759 5.64,3.39759 2.364314,0 4.53477,-1.30751 5.64,-3.39759 l 8.36,-15.77 10.5,19.86 c 1.707211,3.22421 5.056702,5.24072 8.705,5.24072 3.648297,0 6.997789,-2.01651 8.705,-5.24072 l 48,-90.25 c 1.5893,-2.970574 1.49395,-6.5591519 -0.25085,-9.4411411 C 125.27436,2.0368696 122.13886,0.28884316 118.77,0.32 Z M 76,38.45 55,77.83 c -0.7215,1.360201 -2.13529,2.210648 -3.675,2.210648 -1.53971,0 -2.953501,-0.850447 -3.675,-2.210648 l -20.73,-39 C 26.280421,37.629796 26.317769,36.18196 27.018378,35.016327 27.718987,33.850695 28.980019,33.138359 30.34,33.14 h 42.42 c 1.27814,-0.01967 2.471383,0.638051 3.1372,1.729251 0.665817,1.0912 0.704917,2.453148 0.1028,3.580749 z" id="path15" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div className="name">
                                {" "}
                                <h4 className='bold'> Vtex architecture solutions </h4>
                            </div>
                            <div className="desc">
                                <div>
                                    <p>
                                        VTEX solution architect, I optimize your e-commerce with seamless integration, optimal performance, and user-centric experiences. Trust me for online success!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                    {/* service item */}
                    <div className="col col-d-6 col-t-6 col-m-12">
                        <div className="service-item">
                            <div className="icon">
                                {" "}
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" width="800px" height="800px" viewBox="0 0 96.24 96.24">
                                    <g>
                                        <path d="M48.122,0C21.587,0,0.001,21.585,0.001,48.118c0,26.535,21.587,48.122,48.12,48.122c26.532,0,48.117-21.587,48.117-48.122   C96.239,21.586,74.654,0,48.122,0z M4.857,48.118c0-6.271,1.345-12.227,3.746-17.606l20.638,56.544   C14.81,80.042,4.857,65.243,4.857,48.118z M48.122,91.385c-4.247,0-8.346-0.623-12.222-1.763L48.88,51.903l13.301,36.433   c0.086,0.215,0.191,0.411,0.308,0.596C57.992,90.514,53.16,91.385,48.122,91.385z M54.083,27.834   c2.604-0.137,4.953-0.412,4.953-0.412c2.33-0.276,2.057-3.701-0.277-3.564c0,0-7.007,0.549-11.532,0.549   c-4.25,0-11.396-0.549-11.396-0.549c-2.332-0.137-2.604,3.427-0.273,3.564c0,0,2.208,0.275,4.537,0.412l6.74,18.469l-9.468,28.395   L21.615,27.835c2.608-0.136,4.952-0.412,4.952-0.412c2.33-0.275,2.055-3.702-0.278-3.562c0,0-7.004,0.549-11.53,0.549   c-0.813,0-1.77-0.021-2.784-0.052C19.709,12.611,33.008,4.856,48.122,4.856c11.265,0,21.519,4.306,29.215,11.357   c-0.187-0.01-0.368-0.035-0.562-0.035c-4.248,0-7.264,3.702-7.264,7.679c0,3.564,2.055,6.582,4.248,10.146   c1.647,2.882,3.567,6.585,3.567,11.932c0,3.704-1.422,8-3.293,13.986l-4.315,14.421L54.083,27.834z M69.871,85.516l13.215-38.208   c2.471-6.171,3.29-11.106,3.29-15.497c0-1.591-0.104-3.07-0.292-4.449c3.38,6.163,5.303,13.236,5.301,20.758   C91.384,64.08,82.732,78.016,69.871,85.516z" />
                                    </g>
                                </svg>
                            </div>
                            <div className="name">
                                {" "}
                                <h4 className='bold'> WordPress Development </h4>
                            </div>
                            <div className="desc">
                                <div>
                                    <p>
                                        Transform your business websites with expert WordPress development services, enhancing functionality and boosting online presence.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <h3 className="title bold">
                    <span>Pricing</span>
                </h3>
                <div className='description'>
                    <div className="col flex space-evenly service-items border-line-v">
                        <div className="service-item">
                            <div className="icon">
                                {" "}
                                <svg width="304.60388" height="109.53113" viewBox="0 0 304.60388 109.53113" version="1.1" id="svg20">
                                    <g id="Page-1" stroke="none" transform="translate(-48.186108,-47.31959)">
                                        <g id="Artboard">
                                            <g id="vtex-logo" transform="translate(48,47)">
                                                <path d="m 220.35,41.34 h -10.92 v 37.38 c -0.005,0.704658 -0.57534,1.274558 -1.28,1.28 h -8.41 c -0.70466,-0.0054 -1.27456,-0.575342 -1.28,-1.28 V 41.34 h -11 c -0.33306,0.01356 -0.65736,-0.108641 -0.89866,-0.338623 C 186.32005,40.771395 186.18243,40.453328 186.18,40.12 V 33.5 c 0.002,-0.333328 0.14005,-0.651395 0.38134,-0.881377 0.2413,-0.229982 0.5656,-0.352182 0.89866,-0.338623 h 32.87 c 0.70901,-0.03402 1.3123,0.511172 1.35,1.22 v 6.62 c -0.0377,0.700724 -0.62863,1.242773 -1.33,1.22 z" id="Shape" />
                                                <path d="m 255.37,79.75 c -4.30509,0.615781 -8.65146,0.896624 -13,0.84 -8.29,0 -15.61,-2.12 -15.61,-13.81 V 45.45 c 0,-11.69 7.39,-13.74 15.67,-13.74 4.31504,-0.05911 8.62812,0.218397 12.9,0.83 0.9,0.13 1.28,0.45 1.28,1.28 v 6 c -0.005,0.704658 -0.57534,1.274558 -1.28,1.28 h -13.5 c -3,0 -4.11,1 -4.11,4.37 v 5.84 h 17.14 c 0.70466,0.0054 1.27456,0.575342 1.28,1.28 v 6.1 c -0.005,0.704658 -0.57534,1.274558 -1.28,1.28 h -17.14 v 6.81 c 0,3.34 1.09,4.37 4.11,4.37 h 13.54 c 0.70466,0.0054 1.27456,0.575342 1.28,1.28 v 6 c 0.01,0.8 -0.38,1.19 -1.28,1.32 z" id="path9" />
                                                <path d="m 303.83,80 h -10.21 c -0.71202,0.0529 -1.38046,-0.347368 -1.67,-1 l -8.86,-14 -8,13.74 c -0.45,0.77 -0.9,1.28 -1.61,1.28 H 264 c -0.24831,0.04452 -0.50356,-0.02356 -0.69672,-0.18581 C 263.11012,79.671935 262.99901,79.432264 263,79.18 c 0.0131,-0.157131 0.0573,-0.310094 0.13,-0.45 L 277.06,55.54 263,33.5 c -0.0725,-0.118382 -0.11698,-0.251786 -0.13,-0.39 0.0476,-0.506319 0.49306,-0.880511 1,-0.84 h 10.34 c 0.71,0 1.22,0.64 1.61,1.22 l 8.22,13 8,-13 c 0.28992,-0.657457 0.89863,-1.118721 1.61,-1.22 h 9.51 c 0.50694,-0.04051 0.9524,0.333681 1,0.84 -0.013,0.138214 -0.0575,0.271618 -0.13,0.39 l -14,22.17 14.57,23 c 0.11259,0.195504 0.17768,0.414728 0.19,0.64 -0.12,0.44 -0.45,0.69 -0.96,0.69 z" id="path11" />
                                                <path d="m 170.8,32.41 c -0.47969,-0.01067 -0.89936,0.320862 -1,0.79 l -9.33,34.52 c -0.13,0.71 -0.32,1 -0.9,1 -0.58,0 -0.77,-0.26 -0.9,-1 l -9.3,-34.52 c -0.10064,-0.469138 -0.52031,-0.800674 -1,-0.79 h -9.18 c -0.30814,-0.0076 -0.60256,0.127382 -0.79796,0.36577 -0.1954,0.238388 -0.26995,0.553566 -0.20204,0.85423 0,0 11.39,39.57 11.51,40 1.52,4.72 5.21,7 9.9,7 4.49956,0.161453 8.55216,-2.704023 9.9,-7 0.18,-0.54 11.32,-40 11.32,-40 0.0643,-0.2992 -0.0119,-0.611392 -0.20682,-0.847296 C 180.41822,32.5468 180.12596,32.413159 179.82,32.42 Z" id="path13" />
                                                <path d="M 118.77,0.32 H 23.05 C 19.586315,0.35166678 16.388901,2.1841531 14.61068,5.1567016 12.832459,8.12925 12.729906,11.813127 14.34,14.88 l 9.58,18.24 H 6.56 C 4.3421294,33.079643 2.2685207,34.216027 1.109161,36.107181 -0.05019865,37.998335 -0.12215931,40.361817 0.92,42.32 l 30.8,58.2 c 1.10523,2.09008 3.275685,3.39759 5.64,3.39759 2.364314,0 4.53477,-1.30751 5.64,-3.39759 l 8.36,-15.77 10.5,19.86 c 1.707211,3.22421 5.056702,5.24072 8.705,5.24072 3.648297,0 6.997789,-2.01651 8.705,-5.24072 l 48,-90.25 c 1.5893,-2.970574 1.49395,-6.5591519 -0.25085,-9.4411411 C 125.27436,2.0368696 122.13886,0.28884316 118.77,0.32 Z M 76,38.45 55,77.83 c -0.7215,1.360201 -2.13529,2.210648 -3.675,2.210648 -1.53971,0 -2.953501,-0.850447 -3.675,-2.210648 l -20.73,-39 C 26.280421,37.629796 26.317769,36.18196 27.018378,35.016327 27.718987,33.850695 28.980019,33.138359 30.34,33.14 h 42.42 c 1.27814,-0.01967 2.471383,0.638051 3.1372,1.729251 0.665817,1.0912 0.704917,2.453148 0.1028,3.580749 z" id="path15" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div className='pricing'>
                            <div className='name bold'>
                                <h4>E-commerce architect engineer</h4>
                                <h5 className='light'>
                                    Elevate your e-commerce vision! Explore unmatched architecture expertise.
                                </h5>
                            </div>
                            <div className="amount">
                                <span className="dollar">
                                    <span> $ </span>
                                </span>
                                <span className="number">
                                    <span> 40 </span>
                                </span>
                                <span className="period">
                                    <span> USD per hour of working </span>
                                </span>
                            </div>
                            {/* <a className='subs-button' href="https://calendly.com/serbeldiaz/30min" rel="noreferrer" target='_blank'>
                                I evaluate your problem for Free
                            </a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InfoCardProfile;